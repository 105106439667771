import React from "react";
import "../scss/works.scss";
import { v4 as uuidv4 } from "uuid";
import Card from "../components/Card";
import Carousel from "../components/Carousel";
import {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
} from "../source/works";

const Works = () => {
  let cards = [
    {
      key: uuidv4(),
      content: <Card imagen={image1} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image2} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image3} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image4} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image5} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image6} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image7} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image8} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image9} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image10} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image11} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image12} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image13} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image14} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image15} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image16} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image17} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image18} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image19} />,
    },
    {
      key: uuidv4(),
      content: <Card imagen={image20} />,
    },
  ];
  return (
    <div className="content__width">
      <div className="works__content">
        <div className="works__main-info">
          <h2 className="works__title">
            ТЕРРИТОРИЯ <br /> ИНТЕРЬЕРА
          </h2>
          <p className="works__description">
            Занимается разработкой, производством и реализацией корпусной мебели
            по всей России с 2014 года. Высокое качество нашей мебели
            достигается благодаря использованию современного оборудования и
            передовых технологий.
          </p>
        </div>
        <div className="works__carosel">
          <Carousel
            cards={cards}
            height="500px"
            width="20%"
            margin="0 auto"
            offset={2}
            showArrows={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Works;
