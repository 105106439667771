import React from "react";

import "../scss/about.scss";

const About = () => {
  return (
    <div className="content__width">
      <div className="about__уer">
        <div className="about__content">
          <div className="content__card">
            <h2 className="about__title">Территория<br />интерьера </h2>
            <h3>Наши преимущества:</h3>
            <div className="card__scroll">
              <div className="cards">
                <div className="card">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 99 110"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M97.1396 59.7271C98.9667 56.6333 100 53.3333 100 49.3438C100 40.174 92.2462 31.5146 82.1208 31.5146H74.5208C75.5458 28.8458 76.3646 25.6542 76.3646 21.8188C76.3646 6.65333 68.5125 0 56.5167 0C43.6819 0 44.414 19.7777 41.5667 22.625C36.8277 27.364 31.2302 36.4681 27.2417 40H6.66667C2.98479 40 0 42.9848 0 46.6667V96.6667C0 100.349 2.98479 103.333 6.66667 103.333H20C23.1027 103.333 25.71 101.214 26.4537 98.3437C35.7265 98.5523 42.0912 106.665 63.4958 106.665C65 106.665 66.6667 106.667 68.125 106.667C84.191 106.667 91.4554 98.4535 91.6542 86.8063C94.429 82.9677 95.8831 77.8225 95.2667 72.85C97.3196 69.0058 98.1133 64.4452 97.1396 59.7271ZM84.275 70.9417C86.8917 75.3437 84.5375 81.2354 81.3708 82.9354C82.975 93.0979 77.7025 96.6646 70.3042 96.6646H62.425C47.5002 96.6646 37.8356 88.7854 26.6667 88.7854V50H28.9417C34.85 50 43.1042 35.2312 48.6375 29.6958C54.5458 23.7875 52.5771 13.9396 56.5167 10C66.3646 10 66.3646 16.8708 66.3646 21.8188C66.3646 29.9792 60.4562 33.6354 60.4562 41.5146H82.1208C86.5187 41.5146 89.9812 45.4542 90 49.3937C90.0187 53.3312 87.3292 57.2708 85.3604 57.2708C88.1706 60.3031 88.771 66.695 84.275 70.9417ZM18.3333 90C18.3333 92.7615 16.0948 95 13.3333 95C10.5719 95 8.33333 92.7615 8.33333 90C8.33333 87.2385 10.5719 85 13.3333 85C16.0948 85 18.3333 87.2385 18.3333 90Z"
                      fill="white"
                    />
                  </svg>
                  <p>Высокое качество выпускаемой продукции</p>
                </div>
                <div className="card">
                  <svg
                    // className="size"
                    width="100"
                    height="100"
                    viewBox="10 10 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M60 105C84.8528 105 105 84.8528 105 60C105 35.1472 84.8528 15 60 15C35.1472 15 15 35.1472 15 60C15 84.8528 35.1472 105 60 105Z"
                      stroke="white"
                      strokeWidth="8.81323"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M60 35V60L75 75"
                      stroke="white"
                      strokeWidth="8.81323"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>Короткие сроки исполнения заказов</p>
                </div>
                <div className="card">
                  <svg
                    width="100"
                    height="97"
                    viewBox="0 0 100 97"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M77.8003 96C77.0007 96.0032 76.2119 95.8146 75.5003 95.45L50.0003 82.1L24.5003 95.45C23.6722 95.8854 22.7387 96.0797 21.8057 96.011C20.8727 95.9422 19.9777 95.6131 19.2224 95.0609C18.4672 94.5088 17.882 93.7559 17.5333 92.8878C17.1847 92.0196 17.0866 91.0711 17.2503 90.15L22.2503 62L1.65028 42C1.00757 41.3586 0.551636 40.5543 0.331417 39.6734C0.111197 38.7925 0.135003 37.8683 0.400278 37C0.690077 36.1113 1.22316 35.3217 1.93903 34.7207C2.6549 34.1198 3.52489 33.7315 4.45028 33.6L32.9503 29.45L45.5003 3.79995C45.9097 2.9546 46.549 2.24166 47.3448 1.74282C48.1407 1.24398 49.061 0.979401 50.0003 0.979401C50.9396 0.979401 51.8599 1.24398 52.6557 1.74282C53.4516 2.24166 54.0909 2.9546 54.5003 3.79995L67.2003 29.4L95.7003 33.55C96.6257 33.6815 97.4957 34.0698 98.2115 34.6707C98.9274 35.2717 99.4605 36.0613 99.7503 36.95C100.016 37.8183 100.039 38.7425 99.8191 39.6234C99.5989 40.5043 99.143 41.3086 98.5003 41.95L77.9003 61.95L82.9003 90.1C83.0788 91.0373 82.9853 92.0062 82.6309 92.8922C82.2765 93.7782 81.676 94.5443 80.9003 95.1C79.9949 95.7344 78.9047 96.0509 77.8003 96ZM50.0003 71.5C50.8018 71.4797 51.5946 71.6693 52.3003 72.05L71.1503 82.05L67.5503 61C67.4106 60.1962 67.4697 59.3705 67.7225 58.5948C67.9752 57.8192 68.4139 57.1171 69.0003 56.55L84.0003 41.9L63.0003 38.8C62.2302 38.6449 61.5075 38.3106 60.8907 37.8241C60.2739 37.3377 59.7805 36.7127 59.4503 36L50.0003 17.25L40.5503 36C40.1885 36.7185 39.6587 37.3391 39.0059 37.8091C38.3531 38.2792 37.5964 38.5848 36.8003 38.7L15.8003 41.8L30.8003 56.45C31.3867 57.0171 31.8254 57.7192 32.0781 58.4948C32.3308 59.2705 32.39 60.0962 32.2503 60.9L28.6503 81.7L47.5003 71.7C48.299 71.403 49.1645 71.3338 50.0003 71.5Z"
                      fill="white"
                    />
                  </svg>
                  <p>Индивидуальный подход</p>
                </div>
                <div className="card">
                  <svg
                    // className="size1"
                    width="120"
                    height="120"
                    viewBox="15 15 90 90"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25 105C27.5 82.5 37.5 65 60 55"
                      stroke="white"
                      strokeWidth="6.86608"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M45.0002 90C76.0902 90 97.5002 73.56 100 30V20H79.9302C34.9302 20 20.0002 40 19.9302 65C19.9302 70 19.9302 80 29.9302 90H44.9302H45.0002Z"
                      stroke="white"
                      strokeWidth="6.86608"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>Качественные материалы</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content__position">
            <div className="content__gift">
              <p className="gift__title">
                Что мы можем подарить своему клиенту:
              </p>
              <ul className="gift__list">
                <li>Бесплатный замер по г.Волжский и г.Волгограду</li>
                <li>Бесплатный дизайн проект (визуализация)</li>
                <li>
                  При заказе кухонного гарнитура: мойка, вытяжка, смеситель
                  <br /> (все индивидуально и зависит от суммы заказа)
                </li>
                <li>Бесплатная доставка</li>
              </ul>
            </div>
            <p className="info">Компания работает с 01.09.2014</p>
          </div>
        </div>
        <div className="footer__text">
          <p>
            Правовая форма: Общество с ограниченной ответственностью «ЮГ-Рост»
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
